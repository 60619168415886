<template>
  <div>
    <el-card>
      <div slot="header">
        <span>提现</span>
      </div>

      <el-form :inline="true" label-width="120px" size="mini">
        <el-form-item>
          <el-button type="primary" @click="beforeInsert()">提现</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryMyEarnings()">我的收益</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryBasic()">刷新</el-button>
        </el-form-item>
      </el-form>
      <div>
        <el-table
          :max-height="500"
          :height="500"
          :data="tableData"
          border
          style="width: 100%"
          :header-cell-style="{ 'text-align': 'center' }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column type="index" label="序号" fixed="left"/>
          <el-table-column prop="money" label="提现金额"></el-table-column>
          <el-table-column prop="withdrawOrder" label="提现订单号"></el-table-column>
          <el-table-column prop="actualMoney" label="实际到账金额"></el-table-column>
          <el-table-column prop="balance" label="手续费"></el-table-column>
          <el-table-column prop="rate" label="本次提现利率 单位%" width="150"></el-table-column>
          <el-table-column prop="applyTime" label="提交时间" width="150"></el-table-column>
          <el-table-column prop="phoneNum" label="联系电话" width="150"></el-table-column>
          <el-table-column prop="applyName" label="申请人姓名" width="150"></el-table-column>
          <el-table-column prop="applyAcc" label="提现支付宝账号" width="150"></el-table-column>
          <el-table-column prop="withdrwInfo" label="审核备注" width="150"></el-table-column>
          <el-table-column prop="status" label="状态" width="150">
            <template slot-scope="scope">
              <span v-if="scope.row.status===-1">未通过</span>
              <span v-if="scope.row.status===0">审核中</span>
              <span v-if="scope.row.status===1">已提现</span>
              <span v-if="scope.row.status===2">提现失败</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryBO.page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="queryBO.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </el-card>
    <el-dialog
      title="提交提现"
      :visible.sync="dialogVisible"
      width="30%">
      <div>
        <el-form label-width="180px" :model="userApply" ref="ruleForm" :rules="rules" size="mini">
          <el-form-item label="用户手机号:">
            {{userApplyInfo.phone}}
          </el-form-item>
          <el-form-item label="用户编号:">
            {{userApplyInfo.userNo}}
          </el-form-item>
          <el-form-item label="总资产:">
            {{userApplyInfo.amount}}
          </el-form-item>
          <el-form-item label="当前提现利率:">
            {{userApplyInfo.cashNum3}} %
          </el-form-item>
          <el-form-item label="请输入提现金额:" prop="money">
            <el-input-number v-model="userApply.money" placeholder="请输入理由"></el-input-number>
          </el-form-item>
          <el-form-item label="请选择提现类型:" prop="withdrwType">
            <el-select v-model="userApply.withdrwType" placeholder="请选择" style="width: 130px">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="收款人银行卡账号:" v-if="userApply.withdrwType==1" prop="bankAccount">
            <el-input v-model="userApply.bankAccount"  placeholder="请输入收款人银行卡账号"></el-input>
          </el-form-item>
          <el-form-item label="收款人银行:" v-if="userApply.withdrwType==1" prop="bankType">
            <el-input v-model="userApply.bankType" placeholder="请输入收款人银行"></el-input>
          </el-form-item>
          <el-form-item label="提现支付宝账号:" v-if="userApply.withdrwType==0" prop="applyAcc">
            <el-input v-model="userApply.applyAcc" placeholder="请输入支付宝账号"></el-input>
          </el-form-item>
          <el-form-item label="收款人电话:" prop="phoneNum">
            <el-input v-model="userApply.phoneNum" placeholder="请输入联系人手机号"></el-input>
          </el-form-item>
          <el-form-item label="收款人姓名:" prop="applyName">
            <el-input v-model="userApply.applyName" placeholder="请输入联系人姓名"></el-input>
          </el-form-item>

          <el-form-item label="短信验证:" prop="phoneCode">
            <el-input v-model="userApply.phoneCode" placeholder="请输入短信验证码">
              <template #append>
                <el-button @click="sendCode" :disabled="codeButton.flag">{{codeButton.codeText}}</el-button>
              </template>
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="commit" size="mini">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="priceDialog"
      width="30%">
      <div>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>我的收益</span>
          </div>
          <div style="color: #409EFF;text-align: center">
            <countTo style="text-align: center" :decimals="2" ref="countTo" :startVal='priceCount.startVal' :endVal='priceCount.endVal' suffix="RMB" decimal="." prefix="￥" :duration='1500'></countTo>
          </div>
        </el-card>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="priceDialog = false">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import countTo from 'vue-count-to';

  export default {
    name: "price-list",
    components: {countTo},
    data() {
      return {
        queryBO: {
          page: 1,
          pageSize: 10
        },
        tableData: [],
        total: 0,
        province: [],
        options: [
          {
            value: 0,
            label: "支付宝"
          },
          {
            value: 1,
            label: "银行卡转账"
          },
        ],
        userApply: {
          money: 0,
          phoneNum: '',
          bankAccount: '',
          bankType: '',
          withdrwType: null,
          applyName: '',
          applyAcc: '',
          phoneCode: ''
        },
        rules: {
          phoneNum: [
            {required: true, message: '请输入手机号', trigger: 'blur'},
            {min: 11, max: 11, message: '长度为 11 个字符', trigger: 'blur'}
          ],
          money: [
            {required: true, message: '请输入金额', trigger: 'blur'},
          ],
          applyName: [
            {required: true, message: '请输入联系人姓名', trigger: 'blur'},
          ],
          withdrwType: [
            {required: true, message: '请选择提现类型', trigger: 'blur'},
          ],
          bankAccount: [
            {required: true, message: '请输入收款人银行卡账号', trigger: 'blur'},
          ],
          applyAcc: [
            {required: true, message: '请输入支付宝账号', trigger: 'blur'},
          ],
          bankType: [
            {required: true, message: '请输入收款人银行', trigger: 'blur'},
          ],
          phoneCode: [
            {required: true, message: '请输入验证码', trigger: 'blur'},
          ]
        },
        courseName: '',
        dialogVisible: false,
        userApplyInfo: {
          phone: '',
          userNo: '',
          money: '',
          cashNum3: ''
        },
        codeButton: {
          codeText: '获取',
          flag: false,
          countdown: 60,
          clear: ''
        },
        priceCount:{
          startVal: 0,
          endVal: 0
        },
        priceDialog:false
      }
    },
    methods: {
      queryMyEarnings(){
        this.openLoadingView();
        this.$axios_supermallData.get('hnjxjy-core/eduStreamWithdrw/queryAccountBalance').then(res=>{
          if (res.data.code === 2000){
            this.priceCount.endVal = parseInt(res.data.data.amount);
            this.priceDialog = true;
          }
          this.loadingView.close()
        })
      },
      sendCode() {
        this.openLoadingView()
        this.codeButton.flag = true;
        this.codeButton.codeText = '正在发送';
        this.$axios_supermallData.get('hnjxjy-core/eduStreamWithdrw/verificationCode').then(res => {
          if (res.data.code === 2000) {
            this.$message.success('验证码已发送');
            this.loadingView.close();
            this.tempcode();
          } else {
            this.codeButton.flag = false;
            // this.$message.error(res.data.message)
            this.codeButton.codeText = '获取';
            this.loadingView.close();
          }
        }).catch((err) => {
          this.loadingView.close()
        });
      },
      tempcode() {
        const TIME_COUNT = 60;
        let capt_str = '';
        if (!this.codeButton.clear) {
          this.codeButton.countdown = TIME_COUNT;
          this.clear = setInterval(() => {
            if (this.codeButton.countdown > 0 && this.codeButton.countdown <= TIME_COUNT) {
              this.codeButton.countdown--;
              capt_str = this.codeButton.countdown + "秒";
              this.codeButton.codeText = capt_str
            } else {
              capt_str = "重发";
              this.codeButton.flag = false;
              this.codeButton.codeText = capt_str;
              clearInterval(this.codeButton.clear);
              this.codeButton.clear = null;
            }
          }, 1000)
        }
      },
      beforeInsert() {
        this.openLoadingView();
        this.$axios_supermallData.get('hnjxjy-core/eduStreamWithdrw/userApplyInfo').then(res => {
          if (res.data.code === 2000) {
            this.userApplyInfo = res.data.data;
            this.userApply = {
              money: 0,
              phoneNum: '',
              applyName: '',
              applyAcc: '',
              bankType: '',
              bankAccount: '',
              withdrwType: '',
              phoneCode: ''
            };
            this.dialogVisible = true;
          }
          this.loadingView.close();
        })
      },
      commit() {
        this.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            let formData = new FormData();
            formData.append('money', this.userApply.money);
            formData.append('phoneNum', this.userApply.phoneNum);
            formData.append('applyName', this.userApply.applyName);
            formData.append('withdrwType', this.userApply.withdrwType);
            formData.append('bankAccount', this.userApply.bankAccount);
            formData.append('bankType', this.userApply.bankType);
            formData.append('applyAcc', this.userApply.applyAcc);
            formData.append('phoneCode', this.userApply.phoneCode);
            this.openLoadingView();
            this.$axios_supermallData.post('hnjxjy-core/eduStreamWithdrw/userApply', formData).then(response => {
              if (response.data.code === 2000) {
                this.$message.success("提交成功");
                this.dialogVisible = false;
                this.queryBasic();
              }
            })
          } else {
            return false;
          }
        });
      },
      queryBasic() {
        let userInfo = localStorage.getItem("accountType");
        let data = '?page=' + this.queryBO.page + "&pageSize=" + this.queryBO.pageSize;
        this.openLoadingView();
        this.$axios_supermallData.get('hnjxjy-core/eduStreamWithdrw/queryUserApply' + data).then(response => {
          if (response.data.code === 2000) {
            this.tableData = response.data.data.list;
            this.total = response.data.data.total;
            this.loadingView.close();
          }
        })
      },
      handleSizeChange(val) {
        this.queryBO.pageSize = val;
        this.queryBasic()
      },
      handleCurrentChange(val) {
        this.queryBO.page = val;
        this.queryBasic()
      },
    },
    mounted() {
      this.queryBasic();
    }
  }
</script>

<style scoped>

</style>
